import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import DashboardTable from 'app/common/components/Table/table';
import { useSkillSlice } from './skillSlice';
import { SkillSelector } from './skillSlice/selector';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { threeDots } from '../../assets/Common/commonImages';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import { SKILL_STATUS } from 'app/common/constants/constants';
import Dropdown from 'app/common/components/DropdownNew';
import { SkillData } from './types';
import { selectUser } from 'app/common/slices/authslice/selectors';
import {
  handleDotsClick,
  addDotsEventListener,
  removeDotsEventListener,
} from 'app/common/utils/helperFn';
import { USER_ROLES } from 'app/common/services';
import './style.css';
// const LIMIT = 10;

type competency = {
  value: string;
  name: string;
};
const Skill: React.FC = () => {
  const { actions } = useSkillSlice();
  const permissions = useSelector(SkillSelector);
  const userResp = useSelector(selectUser);
  const { loading, competencyData } = permissions;
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const [query, setQuery] = useState({ competency: [], status: '' });
  const [competency, setCompetency] = useState<competency[]>([]);
  const [skillListingData, setSkillListingData] = useState<SkillData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  let statusArr = [...SKILL_STATUS];

  useEffect(() => {
    dispatch(actions.getSkill({})); //blank object send to avoid ts error, need to check
  }, [actions, dispatch]);

  useEffect(() => {
    dispatch(actions.getCompetency({})); //blank object send to avoid ts error, need to check
    addDotsEventListener();
    return removeDotsEventListener;
  }, [actions, dispatch]);

  useEffect(() => {
    setSkillListingData(permissions?.skillData);
  }, [permissions?.skillData]);

  useEffect(() => {
    if (competency.length === 0) {
      const userDataNew = competencyData.map(data => ({
        value: data._id,
        name: data.name,
      }));
      setCompetency(userDataNew);
    }
  }, [competency.length, competencyData]);

  const deleteSkillHandler = (row: { _id: any; name: any }) => {
    dispatch(
      modalManagerActions.openPopUp({
        modalType: 'ConfirmationSkillPopup',
        modalClassName: `w-[500px] rounded-xl`,
        data: { skillId: row._id, name: row.name },
      }),
    );
  };
  const createSkillListing = row => {
    if (skillListingData?.length) {
      return skillListingData.map(val => {
        if (row?._id !== val?._id) return { value: val?._id, label: val?.name };
        return {};
      });
    }
    return [];
  };
  const rowsPerPage = 10;

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + 1 + (currentPage - 1) * rowsPerPage,
    },
    {
      dataField: 'name',
      text: 'Skill Name',
    },
    {
      dataField: 'aliases',
      text: 'Aliases',
      formatter: (cell: any, row: { aliases: { toString: () => any } }) =>
        row.aliases?.toString(),
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell: any, row: { status: string }) => {
        const status = statusArr.filter(status => status.value === row?.status);
        return status[0].label;
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell: any, row) => (
        <>
          <img
            src={threeDots}
            alt="view"
            className="cursor-pointer h-[18px] mt-2.5"
            onClick={event => handleDotsClick(event, row._id)}
          />
          {}
          <div
            id={row._id}
            className="options-container three-dots bg-white text-black p-4 hidden inline-grid absolute translate-x-7 -translate-y-5 whitespace-nowrap rounded bg-white px-2 py-1 text-black shadow-xl"
          >
            {row?.status !== 'approved' &&
              (userResp?.user?.skillRole === USER_ROLES.APPROVER ||
                userResp?.user?.skillRole === USER_ROLES.SUPER_ADMIN) && (
                <div
                  className="border border-solid border-0  rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                  onClick={() => {
                    dispatch(
                      modalManagerActions.openPopUp({
                        modalType: 'AddSkillPopup',
                        heading: `Approve Skill - ${row?.name}`,
                        modalClassName: `lg:w-4/12 addSkillPopup`,
                        data: {
                          existingData: row,
                          competencyData: competency,
                          approve: true,
                          skillListingData: createSkillListing(row),
                        },
                      }),
                    );
                  }}
                >
                  Approve
                </div>
              )}
            {userResp?.user?.skillRole !== USER_ROLES.VIEWER && (
              <>
                <div
                  className="border border-solid border-0  rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                  onClick={() => {
                    dispatch(
                      modalManagerActions.openPopUp({
                        modalType: 'AddSkillPopup',
                        heading: `Edit Skill - ${row?.name}`,
                        modalClassName: `lg:w-4/12 addSkillPopup`,
                        data: {
                          existingData: row,
                          competencyData: competency,
                        },
                      }),
                    );
                  }}
                >
                  Edit
                </div>
                {row?.status !== 'approved' &&
                  userResp?.user?.skillRole === USER_ROLES.CREATOR && (
                    <div
                      className="rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                      onClick={() => deleteSkillHandler(row)}
                    >
                      Remove
                    </div>
                  )}
              </>
            )}
          </div>
        </>
      ),
    },
  ];

  const filterData = useCallback(
    filterArr => {
      const permiitedValues =
        filterArr.length && filterArr.map(val => val.value);
      return filterArr.length
        ? permissions?.skillData.filter(skill => {
            return skill?.metadata?.competency.some(item =>
              permiitedValues.includes(item),
            );
          })
        : permissions?.skillData;
    },
    [permissions?.skillData],
  );

  const onAdd = useCallback(
    (field: string, value: string) => {
      const updatedValue =
        field === 'status' ? value : [...query[field], value];
      if (field === 'status') {
        let updatedData = [...permissions?.skillData];
        updatedData =
          value !== 'all'
            ? updatedData.filter(data => data?.status === value)
            : updatedData;
        setSkillListingData(updatedData);
      } else {
        const filteredArray = filterData(updatedValue);
        setSkillListingData(filteredArray);
      }
      setQuery(prevDetails => ({
        ...prevDetails,
        [field]: updatedValue,
      }));
    },
    [filterData, permissions?.skillData, query],
  );

  const onRemove = (field: string, value: { value: string }) => {
    const updatedFilter = query[field].filter(
      (i: { value: any }) => i.value !== value.value,
    );
    const filteredArray = filterData(updatedFilter);

    setQuery(prevDetails => ({
      ...prevDetails,
      [field]: updatedFilter,
    }));
    setSkillListingData(filteredArray);
  };

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="heading pb-9">
            <h3 className="text-[#151D48] text-[28px] font-semibold">Skills</h3>
            <span className="text-[#151D48] text-[16px] font-normal opacity-50 ">
              Skills listing page
            </span>
          </div>
          <div className="flex justify-between pb-9">
            <div className="flex filter-group">
              <div className="dropdown">
                <Dropdown
                  options={statusArr}
                  onSelect={data => onAdd('status', data)}
                  selectedOption={query?.status}
                  className={`align-middle drp-2 w-60 mr-4 p-3 border border-solid border-1 border-[#737791] rounded-[4px]`}
                  optionsClassName="custom-options1 top-[87%]"
                  placeholder="Filter by Status"
                />
              </div>

              <Multiselect
                className={`p-0 border border-solid border-1 border-[#737791] rounded-[4px]`}
                // optionsClassName="custom-options1"
                placeholder="Filter by Competency"
                options={competency}
                onSelect={(list, data) => onAdd('competency', data)}
                onRemove={(list, data) => onRemove('competency', data)}
                displayValue={'name'}
                showCheckbox={true}
              />
            </div>
            <button
              onClick={() => {
                dispatch(
                  modalManagerActions.openPopUp({
                    modalType: 'AddSkillPopup',
                    heading: 'Add Skills',
                    modalClassName: `lg:w-4/12 addSkillPopup`,
                    data: { competencyData: competency },
                  }),
                );
              }}
              className="py-4 px-12 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186] h-full"
            >
              <i className="bi bi-plus"></i> Add Skills
            </button>
          </div>
          <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 row col-sm-12">
            {skillListingData && skillListingData.length ? (
              <>
                <DashboardTable
                  data={skillListingData}
                  columns={columns}
                  defaultArray={[{ dataField: 'role', order: 'asc' }]}
                  needPagination={true}
                  className={'skillListing'}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </>
            ) : (
              <div className="text-align-center">No Data Found</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Skill;
