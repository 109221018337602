import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { projectList } from './projectSlice/selector';
import { useProjectSlice } from 'app/pages/Projects/projectSlice/index';
import DashboardTable from 'app/common/components/Table/table';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SkillSelector } from '../Skill/skillSlice/selector';
import './style.css';
import Dropdown from 'app/common/components/DropDown';
import {
  projectStatusOptions,
  REVIEW_STATUS,
} from 'app/common/constants/constants';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useSkillSlice } from '../Skill/skillSlice';
import { infoSmallImage } from 'app/assets/Common/commonImages';
import { ReactTags } from 'react-tag-autocomplete';
import { threeDots } from '../../assets/Common/commonImages';
import {
  addDotsEventListener,
  handleDotsClick,
  removeDotsEventListener,
} from 'app/common/utils/helperFn';
import { DomainSelector } from '../Domain/domainSlice/selector';
import { useDomainSlice } from '../Domain/domainSlice';

function Projects() {
  const dispatch = useDispatch();
  const { actions } = useProjectSlice();
  const projects = useSelector(projectList);
  const { actions: modalActions } = useModalManagerSlice();
  const { actions: skillActions } = useSkillSlice();
  const { actions: domainActions } = useDomainSlice();
  const permissions = useSelector(SkillSelector);
  const { skillData: skillListing, searchedSkills } = permissions;
  const { domainData: domainListing, searchedDomains } =
    useSelector(DomainSelector);
  const {
    projectsData,
    loading,
    statsLoading,
    totalCount,
    count,
    currentPage,
    searchQuery,
    selectedStatusFilter,
    selectedReviewFilter,
    activeCount,
    reviewInProgressCount,
    reviewedCount,
    skillsTag,
    domainsTag,
  } = projects;
  const itemsPerPage = 20;
  const [skillSuggestions, setSkillSuggestions] = useState([]);
  const [domainSuggestions, setDomainSuggestions] = useState([]);
  const [skillSearchQuery, setSkillSearchQuery] = useState('');
  const [domainSearchQuery, setDomainSearchQuery] = useState('');

  useEffect(() => {
    setSkillSuggestions([
      ...searchedSkills?.map(skill => ({ value: skill.id, label: skill.name })),
    ]);
  }, [searchedSkills]);
  useEffect(() => {
    setDomainSuggestions([
      ...searchedDomains?.map(domain => ({
        value: domain.id,
        label: domain.name,
      })),
    ]);
  }, [searchedDomains]);
  useEffect(() => {
    const skillIds = skillsTag?.map(t => t.value);
    const domainIds = domainsTag?.map(t => t.value);
    dispatch(
      actions.getProjects({
        page: currentPage,
        items: itemsPerPage,
        search: searchQuery,
        statusFilter: selectedStatusFilter,
        reviewStatus: selectedReviewFilter,
        skillsQuery: skillIds,
        domainsQuery: domainIds,
      }),
    );
  }, [
    dispatch,
    actions,
    itemsPerPage,
    selectedStatusFilter,
    selectedReviewFilter,
  ]);
  useEffect(() => {
    dispatch(actions.getProjectStats({}));
  }, []);
  useEffect(() => {
    dispatch(skillActions.getSkill({}));
  }, []);
  useEffect(() => {
    dispatch(domainActions.getDomain({}));
  }, []);
  const eventListenerAdded = useRef(false);

  useEffect(() => {
    if (!eventListenerAdded.current) {
      addDotsEventListener();
      eventListenerAdded.current = true;
    }

    return () => removeDotsEventListener();
  }, []);

  const searchProjects = searchStr => {
    const skillIds = skillsTag?.map(t => t.value);
    const domainIds = domainsTag?.map(t => t.value);

    dispatch(actions.setPage(1));
    dispatch(
      actions.getProjects({
        page: 1,
        items: itemsPerPage,
        search: searchStr,
        statusFilter: selectedStatusFilter,
        reviewStatus: selectedReviewFilter,
        skillsQuery: skillIds,
        domainsQuery: domainIds,
      }),
    );
  };

  const debouncedSearch = useCallback(debounce(searchProjects, 500), [
    selectedStatusFilter,
  ]);

  const handleSearchChange = event => {
    dispatch(actions.setSearchQuery({ query: event?.target?.value }));
    debouncedSearch(event?.target?.value);
  };

  const fetchData = () => {
    if (!loading && projectsData?.length < count) {
      const skillIds = skillsTag?.map(t => t.value);
      const domainIds = domainsTag?.map(t => t.value);

      dispatch(actions.setPage(currentPage + 1));
      dispatch(
        actions.getProjects({
          items: itemsPerPage,
          page: currentPage + 1,
          search: searchQuery,
          statusFilter: selectedStatusFilter,
          reviewStatus: selectedReviewFilter,
          skillsQuery: skillIds,
          domainsQuery: domainIds,
        }),
      );
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: '5%' },
    },
    {
      dataField: 'projectName',
      text: 'Project Name',
      formatter: (cell, row) => (
        <Link
          to={`/review/account/${
            row.accountId
          }?projectName=${encodeURIComponent(cell)}`}
          style={{ textDecoration: 'none' }}
        >
          {cell}
        </Link>
      ),
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'buName',
      text: 'BU',
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'skills',
      text: 'Skills',
      formatter: cell => {
        if (!cell || cell.length === 0) {
          return '-';
        }
        const skillNames = cell.map(cell => cell.name);
        const displayedNames = skillNames.slice(0, 2).join(', ');
        const tooltipNames = skillNames.join(', ');
        return (
          <span className="inline-block">
            {displayedNames}
            {skillNames.length > 2 && (
              <img
                src={infoSmallImage}
                alt="ellipsis icon"
                className="h-[17px] cursor-pointer inline-block ml-1"
                title={tooltipNames}
              />
            )}
          </span>
        );
      },
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'domains',
      text: 'Domains',
      formatter: cell => {
        if (!cell || cell.length === 0) {
          return '-';
        }
        const domainNames = cell.map(cell => cell.name);
        const displayedNames = domainNames.slice(0, 2).join(', ');
        const tooltipNames = domainNames.join(', ');
        return (
          <span className="inline-block">
            {displayedNames}
            {domainNames.length > 2 && (
              <img
                src={infoSmallImage}
                alt="ellipsis icon"
                className="h-[17px] cursor-pointer inline-block ml-1"
                title={tooltipNames}
              />
            )}
          </span>
        );
      },
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      formatter: cell => {
        if (!cell) return ' - ';
        const date = new Date(cell);
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
      },
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: cell => {
        if (!cell) return ' - ';
        const date = new Date(cell);
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
      },
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      formatter: cell => {
        const status = REVIEW_STATUS?.find(item => item.value === cell);
        return (
          <div
            className={`${
              status
                ? `border rounded-md w-fit py-1.5 px-4 text-center font-sans text-base font-normal ${status.className}`
                : ''
            }`}
          >
            {status ? status.label : ''}
          </div>
        );
      },
      headerStyle: { width: '10%' },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => (
        <div className="relative">
          <img
            src={threeDots}
            alt="view"
            className="cursor-pointer h-[18px] mt-2.5"
            onClick={event => {
              handleDotsClick(event, row._id);
            }}
          />
          <div>
            <div
              id={row._id}
              className="options-container three-dots bg-white text-black p-4  absolute  whitespace-nowrap rounded  px-2 py-1 shadow-xl z-20 hidden"
            >
              <div
                className="border-0  rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 z-20"
                onClick={() => {
                  dispatch(
                    modalActions.openPopUp({
                      modalType: 'AddSkillPopupInProjects',
                      modalClassName: `w-[500px] rounded-xl`,
                      heading: `Map Skills - ${row.projectName}`,
                      data: { projectId: row._id },
                    }),
                  );
                }}
              >
                Map Skills
              </div>
              <div
                className="border-0  rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 z-20"
                onClick={() => {
                  const approvedDomains = domainListing?.filter(
                    domain => domain.status === 'approved',
                  );
                  dispatch(
                    modalActions.openPopUp({
                      modalType: 'AddDomainPopupInProjects',
                      modalClassName: `w-[500px] rounded-xl`,
                      heading: `Map Domains - ${row.projectName}`,
                      data: {
                        domainsData: approvedDomains,
                        projectId: row._id,
                      },
                    }),
                  );
                }}
              >
                Map Domains
              </div>
            </div>
          </div>
        </div>
      ),
      headerStyle: { width: '10%' },
    },
  ];
  function handleSelects(value) {
    const data =
      projectStatusOptions?.find(option => option.label === value)?.value || '';
    if (data !== selectedStatusFilter) {
      setTimeout(() => {
        dispatch(actions.setPage(1));
        dispatch(actions.setProjectFilterStatus(data));
      }, 200);
    }
  }
  function handleReviewSelects(value) {
    const data =
      REVIEW_STATUS?.find(option => option.label === value)?.value || '';
    if (data !== selectedReviewFilter) {
      setTimeout(() => {
        dispatch(actions.setPage(1));
        dispatch(actions.setReviewFilterStatus(data));
      }, 200);
    }
  }
  const selectedOptionLabel =
    projectStatusOptions?.find(option => option.value === selectedStatusFilter)
      ?.label || 'All';
  const selectedReviewOptionLabel =
    REVIEW_STATUS?.find(option => option.value === selectedReviewFilter)
      ?.label || 'All';

  const fetchSkills = useCallback(
    debounce(query => {
      if (query?.length >= 2) {
        dispatch(skillActions.getSearchedSkills({ search: query }));
      }
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    fetchSkills(skillSearchQuery);
  }, [skillSearchQuery, fetchSkills]);

  const handleSkillsInput = input => {
    setSkillSearchQuery(input);

    if (input.length === 0) {
      dispatch(skillActions.clearSearchedSkills());
    }
  };
  const fetchDomains = useCallback(
    debounce(query => {
      if (query?.length >= 2) {
        dispatch(domainActions.getSearchedDomains({ search: query }));
      }
    }, 300),
    [dispatch],
  );

  useEffect(() => {
    fetchDomains(domainSearchQuery);
  }, [domainSearchQuery, fetchDomains]);

  const handleDomainsInput = input => {
    setDomainSearchQuery(input);

    if (input.length === 0) {
      dispatch(domainActions.clearSearchedDomains());
    }
  };

  const handleChange = (actionType, index, value, type) => {
    let newItems;

    if (type === 'skill') {
      if (actionType === 'add') {
        newItems = [...skillsTag, value];
      } else if (actionType === 'delete') {
        newItems = skillsTag?.filter((tag, i) => i !== index);
      }

      dispatch(actions.setSkillsTag(newItems));
      const skillIds = newItems?.map(t => t.value);
      const domainIds = domainsTag?.map(t => t.value);

      dispatch(
        actions.getProjects({
          skillsQuery: skillIds,
          page: 1,
          items: itemsPerPage,
          search: searchQuery,
          statusFilter: selectedStatusFilter,
          reviewStatus: selectedReviewFilter,
          domainsQuery: domainIds,
        }),
      );
      if (actionType === 'add') setSkillSearchQuery('');
    } else if (type === 'domain') {
      if (actionType === 'add') {
        newItems = [...domainsTag, value];
      } else if (actionType === 'delete') {
        newItems = domainsTag?.filter((domain, i) => i !== index);
      }

      dispatch(actions.setDomainsTag(newItems));
      const domainIds = newItems?.map(d => d.value);
      const skillIds = skillsTag?.map(t => t.value);

      dispatch(
        actions.getProjects({
          skillsQuery: skillIds,
          domainsQuery: domainIds,
          page: 1,
          items: 20,
          search: searchQuery,
          statusFilter: selectedStatusFilter,
          reviewStatus: selectedReviewFilter,
        }),
      );
    }
  };

  return (
    <>
      {!projectsData?.length && (loading || statsLoading) ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="flex justify-between pb-2">
            <div className="heading">
              <h3 className="text-[#151D48] text-[28px] font-semibold">
                Projects
              </h3>
              <span className="text-[#151D48] text-[16px] font-normal opacity-50">
                Projects - Order by newly added
              </span>
            </div>

            <div className="flex w-3/5 items-center gap-4">
              <div className="wrap-search rounded-[6px] w-full relative bg-[#F9FAFB]]">
                <input
                  type="text"
                  placeholder="Search Projects"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="border border-[#737791] w-full h-[40px] px-[12px] text-[#6B7280] rounded-[6px] focus:outline-none"
                />
              </div>
              <div className="wrap-search rounded-[6px] w-full relative  border border-[#737791]">
                <ReactTags
                  tags={skillsTag}
                  selected={skillsTag}
                  suggestions={skillSuggestions}
                  onDelete={tag => handleChange('delete', tag, null, 'skill')}
                  onAdd={tag => handleChange('add', null, tag, 'skill')}
                  onInput={handleSkillsInput}
                  noOptionsText="No matching skills"
                  minQueryLength={2}
                  hidePlaceholder={true}
                  placeholderText={skillsTag.length > 0 ? '' : 'Search skills'}
                />
              </div>

              <div className="wrap-search rounded-[6px] w-full relative border border-[#737791]">
                <ReactTags
                  tags={domainsTag}
                  selected={domainsTag}
                  suggestions={domainSuggestions}
                  onDelete={tag => handleChange('delete', tag, null, 'domain')}
                  onAdd={tag => handleChange('add', null, tag, 'domain')}
                  onInput={handleDomainsInput}
                  noOptionsText="No matching domains"
                  minQueryLength={2}
                  hidePlaceholder={true}
                  placeholderText={
                    domainsTag.length > 0 ? '' : 'Search domains'
                  }
                />
              </div>
            </div>
          </div>
          <div className="pb-7 flex items-baseline justify-between">
            <p className="text-[#151D48] text-[16px] backgroundStyling">
              Total: <span className="opacity-50">{totalCount},</span>
              {'  '} Active: <span className="opacity-50">{activeCount},</span>
              {'  '} Review In Progress:{' '}
              <span className="opacity-50">{reviewInProgressCount},</span>
              {'  '} Reviewed:{' '}
              <span className="opacity-50">{reviewedCount}</span>
            </p>

            <div className="flex gap-4">
              <Dropdown
                options={projectStatusOptions?.map(option => option.label)}
                onSelect={handleSelects}
                selectedOption={selectedOptionLabel}
                selectedOptionStyle={{ padding: '10px' }}
                className="drp-2 relative min-w-[150px] w-full border border-solid border-1 rounded-[4px] border-[#737791]"
                placeholder="Select Project"
                optionsClassName="custom-options1"
              />
              <Dropdown
                options={REVIEW_STATUS?.map(option => option.label)}
                onSelect={handleReviewSelects}
                selectedOption={selectedReviewOptionLabel}
                selectedOptionStyle={{ padding: '10px' }}
                className="drp-2 relative min-w-[150px] w-full border border-solid border-1 rounded-[4px] border-[#737791]"
                placeholder="Select Review Status"
                optionsClassName="custom-options1"
              />
            </div>
          </div>
          <div
            id="scrollableDiv"
            className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 relative"
          >
            <InfiniteScroll
              dataLength={projectsData?.length}
              next={fetchData}
              hasMore={projectsData?.length < count}
              scrollThreshold={0.8}
              height={'70vh'}
              scrollableTarget="scrollableDiv"
              loader={
                (statsLoading || loading) && (
                  <LoaderContainer height={'11vh'}>
                    <LoaderComponent />
                  </LoaderContainer>
                )
              }
            >
              {projectsData?.length ? (
                <DashboardTable
                  data={projectsData}
                  columns={columns}
                  classcategory={'tablereviewpage'}
                  className="sticky-header"
                />
              ) : (
                <p className="text-center">No Data Found</p>
              )}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
}

export default Projects;
