import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { DomainSaga } from './saga';

export const initialState = {
  view: 'Domain',
  error: null,
  loading: false,
  domainData: [],
  rolesDeleted: false,
  domainUpdated: false,
  searchedDomains: [],
};

const slice = createSlice({
  name: 'Domain',
  initialState,
  reducers: {
    getDomain(state) {
      state.loading = true;
    },
    gotDomain(state, action) {
      const Domain = action.payload;
      state.domainData = Domain;
      state.loading = false;
    },
    getSearchedDomains(state) {
      state.loading = true;
    },
    gotSearchedDomains(state, action) {
      const Domain = action.payload;
      state.searchedDomains = Domain;
      state.loading = false;
    },
    clearSearchedDomains(state) {
      state.searchedDomains = [];
      state.loading = false;
    },
    createDomain(state) {
      state.loading = true;
    },
    updateDomain(state) {
      state.loading = true;
    },
    approveDomain(state) {
      state.loading = true;
    },
    domainCreated(state, action) {
      state.domainData = [...state.domainData, action.payload];
    },
    deleteDomain(state) {
      state.loading = true;
    },
    resetState(state) {
      state.domainData = initialState.domainData;
    },
    removeLoader(state) {
      state.loading = false;
    },
  },
});

export const { actions: DomainActions } = slice;

export const useDomainSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: DomainSaga });
  return { actions: slice.actions };
};
