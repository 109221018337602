import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useProjectSlice } from '../projectSlice';
import { projectList } from '../projectSlice/selector';
import { selectUser } from 'app/common/slices/authslice/selectors';
import { USER_ROLES } from 'app/common/services';
import { refreshIcon } from 'app/assets/Common/commonImages';
import { useDomainSlice } from 'app/pages/Domain/domainSlice';
import { DomainSelector } from 'app/pages/Domain/domainSlice/selector';

const AddDomainPopup = props => {
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: domainActions } = useDomainSlice();
  const userDetails = useSelector(selectUser);
  const { domainData: domainListing } = useSelector(DomainSelector);
  const { actions } = useProjectSlice();
  const projects = useSelector(projectList);
  const {
    searchQuery,
    selectedStatusFilter,
    selectedReviewFilter,
    projectsData,
  } = projects;
  const { projectId, heading, domainsData } = props?.data || {};

  const [selectedDomains, setSelectedDomains] = useState([]);

  useEffect(() => {
    const project = projectsData?.find(p => p._id === projectId);
    if (project?.domains) {
      setSelectedDomains(project?.domains);
    }
  }, []);
  const hasPermission =
    userDetails?.user?.skillRole &&
    [USER_ROLES.APPROVER, USER_ROLES.CREATOR, USER_ROLES.SUPER_ADMIN].includes(
      userDetails.user.skillRole,
    );
  const handleRefreshDomains = async () => {
    try {
      dispatch(domainActions.getDomain({}));
    } catch (error) {
      console.error('Error fetching domains:', error);
    }
  };
  const approvedDomains =
    domainListing?.filter(domain => domain.status === 'approved') || [];

  const onAdd = (field, data) => {
    setSelectedDomains(data);
  };

  const onRemove = (field, data) => {
    setSelectedDomains(data);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!selectedDomains?.length) {
      alert('Please select at least one domain.');
      return;
    }
    const finalDomains = selectedDomains?.map(domain => domain._id);

    dispatch(actions.setPage(1));
    dispatch(
      actions.updateProject({
        projectId: projectId,
        domains: finalDomains,
        search: searchQuery,
        statusFilter: selectedStatusFilter,
        reviewStatus: selectedReviewFilter,
      }),
    );
    dispatch(modalManagerActions.closePopUp());
  };

  return (
    <>
      <div className="py-[20px]">
        <p className="text-3xl font-medium text-[#151D48] text-center">
          {heading}
        </p>
        <div className="items-center mb-2">
          <label className="font-normal mb-3">Domains: </label>
          <div className="flex items-center space-x-2">
            <div className="w-[400px] min-h-[44px] border border-[#737791]">
              {' '}
              <Multiselect
                selectedValues={selectedDomains}
                options={approvedDomains}
                onSelect={data => onAdd('domains', data)}
                onRemove={data => onRemove('domains', data)}
                displayValue={'name'}
                showCheckbox
                placeholderText={'Enter Domains'}
                hidePlaceholder={true}
                avoidHighlightFirstOption={true}
              />
            </div>
            <button onClick={handleRefreshDomains} aria-label="Refresh domains">
              <img src={refreshIcon} alt="refresh icon" className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="pt-6 mr-4">
          <span className="font-bold">Note:</span>

          {hasPermission ? (
            <span className="italic">
              {' '}
              In case the domain you want to add is not mentioned in the list ,
              click on the Create domain button below.
            </span>
          ) : (
            <span className="italic px-2">
              In case the domain you want to add is not mentioned in the list ,
              please contact competency owner.
            </span>
          )}
        </div>
        <div className="flex justify-end mt-12">
          {hasPermission && (
            <button
              className="text-[#de1186] py-2 px-6 font-medium text-xl rounded-xl ml-4 border border-solid border-[#de1186]"
              onClick={() => {
                window.open('/domain?openModal=true', '_blank');
              }}
            >
              Create Domain
            </button>
          )}
          <button
            className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl ml-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="text-[#de1186] py-4 px-12 font-medium text-xl rounded-xl ml-4 border border-solid border-[#de1186]"
            onClick={() => dispatch(modalManagerActions.closePopUp())}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default AddDomainPopup;
