import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { projectsActions as actions } from '.';
import { commonActions } from 'app/common/slices/errorBoundarySlice';

export function* getProjects({ payload }) {
  const {
    search,
    items,
    page,
    statusFilter,
    reviewStatus,
    skillsQuery,
    domainsQuery,
  } = payload;
  yield delay(500);
  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/project?page=${page}&items=${items}`;

  if (search && search.trim().length > 0) {
    requestURL += `&search=${encodeURIComponent(search)}`;
  }
  if (statusFilter) {
    requestURL += `&status=${statusFilter}`;
  }
  if (reviewStatus) {
    requestURL += `&reviewStatus=${reviewStatus}`;
  }
  if (skillsQuery?.length > 0) {
    const searchedSkills = skillsQuery
      ?.map(skill => `skills[]=${skill}`)
      .join('&');
    requestURL += `&${searchedSkills}`;
  }
  if (domainsQuery?.length > 0) {
    const searchedDomains = domainsQuery
      ?.map(domain => `domains[]=${domain}`)
      .join('&');
    requestURL += `&${searchedDomains}`;
  }

  try {
    const projectData = yield call(request, requestURL);
    yield put(actions.gotProjects(projectData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}
export function* getProjectStats() {
  yield delay(500);
  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/project/stats`;

  try {
    const projectStatsData = yield call(request, requestURL);
    yield put(actions.gotProjectStats(projectStatsData));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}
export function* updateProject({ payload }) {
  const { projectId, skills, search, statusFilter, reviewStatus, domains } =
    payload || {};

  if (!projectId || (!skills && !domains)) {
    console.error('Invalid payload:', payload);
    yield put(commonActions.setError({ errMsg: 'Invalid request payload' }));
    yield put(actions.removeLoader());
    return;
  }

  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/project/${projectId}`;
  let requestBody = {};
  if (skills) requestBody = { skills };
  if (domains) requestBody = { domains };

  try {
    yield call(request, requestURL, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });
    yield put(
      actions.getProjects({
        page: 1,
        items: 20,
        search,
        statusFilter,
        reviewStatus,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        errMsg: err.message || 'Failed to add skills',
      }),
    );
  } finally {
    yield put(actions.removeLoader());
  }
}

export function* projectSaga() {
  yield takeLatest(actions.getProjects, getProjects);
  yield takeLatest(actions.getProjectStats, getProjectStats);
  yield takeLatest(actions.updateProject, updateProject);
}
