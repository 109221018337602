import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactTags } from 'react-tag-autocomplete';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useDomainSlice } from '../domainSlice';
import '../components/style.css';

const DomainPopup = ({ data }) => {
  const {
    existingData,
    approve,
    domainListingData,
    setQuery,
    setCurrentPage,
    domain,
  } = data;

  const [validationError, setValidationError] = useState({
    name: '',
    replaceAndMergeWith: '',
  });
  const [editDomain, setEditDomain] = useState(false);
  const [domainData, setDomainData] = useState({
    name: '',
    aliases: [],
  });
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: DomainActions } = useDomainSlice();

  useEffect(() => {
    if (existingData && Object.keys(existingData)?.length) {
      const data = { ...existingData };
      data.aliases = getValueLabelArr(existingData?.aliases);
      data.replaceAndMergeWith = [];
      setDomainData(data);
      setEditDomain(true);
    }
  }, [existingData]);

  const getValueLabelArr = arr => {
    if (arr?.length) {
      return arr.map(val => {
        return { value: val, label: val };
      });
    }
    return arr;
  };

  const handleInputChange = useCallback(value => {
    setDomainData(prevDetails => ({
      ...prevDetails,
      name: value,
    }));
    setValidationError(prevDetails => ({
      ...prevDetails,
      name: '',
    }));
  }, []);

  const onAddTag = useCallback((field, value) => {
    setDomainData(prevDetails => {
      const data = { ...prevDetails };
      data[field] = [...data[field], value];
      return {
        ...data,
      };
    });
  }, []);

  const onRemoveTag = useCallback((field, value) => {
    setDomainData(prevDetails => {
      const data = { ...prevDetails };
      data[field] = data[field].filter((_, i) => i !== value);
      return {
        ...data,
      };
    });
    setQuery('all');
  }, []);

  const handleSubmit = useCallback(() => {
    const flattenedDetails = {
      name: domainData?.name,
      aliases: domainData.aliases?.map(v => v.label),
    };

    if (!flattenedDetails.name.trim()) {
      setValidationError(prevDetails => ({
        ...prevDetails,
        name: 'This field is required',
      }));
      return;
    }
    if (editDomain && !approve) {
      flattenedDetails._id = domainData._id;
      dispatch(
        DomainActions.updateDomain({
          domainId: domainData._id,
          domainDetails: flattenedDetails,
        }),
      );
    } else if (approve) {
      if (domainData?.replaceAndMergeWith.length > 1) {
        setValidationError(prevDetails => ({
          ...prevDetails,
          replaceAndMergeWith: 'Only 1 domain is allowed',
        }));
        return;
      }
      if (domainData?.replaceAndMergeWith.length) {
        flattenedDetails.replaceAndMergeWith =
          domainData?.replaceAndMergeWith[0]?.value;
      }
      const userConfirmed = window.confirm('Are you sure you want to approve?');
      if (userConfirmed) {
        dispatch(
          DomainActions.approveDomain({
            domainId: domainData._id,
            domainDetails: flattenedDetails,
          }),
        );
      } else {
        return;
      }
      setQuery({ competency: [], status: '' });
    } else {
      dispatch(DomainActions.createDomain(flattenedDetails));
      const page = Math.ceil(((domain?.length ?? 0) + 1) / 10);
      setCurrentPage(page || 1);
    }
    setQuery('all');
    dispatch(modalManagerActions.closePopUp());
    setValidationError(prevDetails => ({
      ...prevDetails,
      name: '',
    }));
  }, [
    domainData.name,
    domainData.aliases,
    domainData?._id,
    domainData?.replaceAndMergeWith,
    editDomain,
    approve,
    dispatch,
    modalManagerActions,
    DomainActions,
  ]);

  return (
    <div className="flex flex-col">
      <label className="mt-2 ml-2.5 mb-3 font-normal">Name*</label>
      <input
        type="text"
        className="drp-1 ml-2.5 mb-3 pl-2.5 relative px-4 py-3.5 rounded-xl border-solid border border-[#737791] focus:outline-none focus:border-[#323334]"
        onChange={e => handleInputChange(e.target.value)}
        placeholder="Enter domain name"
        value={domainData.name}
      />
      {validationError?.name && (
        <div className="text-red-500 ml-2.5">{validationError?.name}</div>
      )}

      <label className="mt-2 ml-2.5 mb-3 font-normal">Aliases</label>
      <div className="flex flex-col ml-2.5">
        <div className=" mb-2">
          <ReactTags
            selected={domainData.aliases}
            suggestions={[]}
            onAdd={data => onAddTag('aliases', data)}
            onDelete={data => onRemoveTag('aliases', data)}
            allowNew
            placeholderText={'Enter aliases'}
          />
        </div>
      </div>
      {existingData && approve && (
        <div className="flex flex-col ml-2.5 mt-3">
          <label className="mt-2 mb-3 font-normal">
            Replace and Merge with
          </label>
          <div className=" mb-2">
            <ReactTags
              selected={domainData?.replaceAndMergeWith || []}
              suggestions={domainListingData}
              onAdd={data => onAddTag('replaceAndMergeWith', data)}
              onDelete={data => onRemoveTag('replaceAndMergeWith', data)}
              allowNew
              placeholderText={'Search for a domain...'}
            />
            {validationError?.replaceAndMergeWith && (
              <div className="text-red-500 ml-2.5">
                {validationError?.replaceAndMergeWith}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex justify-end mt-12">
        <button
          className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl ml-4"
          onClick={handleSubmit}
        >
          {approve ? 'Approve' : 'Submit'}
        </button>
        <button
          className="text-[#de1186] py-4 px-12 font-medium text-xl rounded-xl ml-4 border border-solid border-[#de1186]"
          onClick={() => {
            dispatch(modalManagerActions.closePopUp());
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DomainPopup;
