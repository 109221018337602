import { React, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DashboardTable from 'app/common/components/Table/table';
import {
  tableview,
  threeDots,
  // stars,
  leftArrow,
} from 'app/assets/Common/commonImages';
import Breadcrumb from 'app/common/components/Breadcrumb';
import Button from 'app/common/components/Button';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import Dropdown from 'app/common/components/DropDown';
import { useReviewSlice } from '../../reviewSlice';
import { selectReview } from '../../reviewSlice/selectors';
import Loader from 'app/common/components/Loader';
import { LoaderContainer } from 'app/pages/Setup/style';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services/permission/permissionConstant';

import {
  // revertSlug,
  // slug,
  handleDotsClick,
  addDotsEventListener,
  removeDotsEventListener,
} from 'app/common/utils/helperFn';
import './style.css';

function TrackPage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions: reviewActions } = useReviewSlice();
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const projectName = queryParams.get('projectName');

  // const decodedBusinessName = revertSlug(params.buName);
  // const decodedHeading = revertSlug(params.name);
  const { actions: modalManagerActions } = useModalManagerSlice();
  const reviewResponse = useSelector(selectReview);

  const [projectData, setProjectData] = useState([]);

  const {
    projectAccountDetails,
    projectReviewDetails,
    loading,
    // selectedProject,
  } = reviewResponse;

  const breadcrumbItems = [
    { text: 'Review', link: '/review' },
    {
      text: projectAccountDetails?.businessUnitName,
      link: -1,
    },
    { text: projectAccountDetails?.accountName },
  ];

  const [selectedOption, setSelectedOption] = useState({});
  const projectTrackDetails =
    projectReviewDetails[selectedOption.id]?.tracks || [];
  const dataObject = {
    id: selectedOption.id,
    trackData: projectTrackDetails,
  };

  // edit track handler
  const handleEdit = (row, params) => {
    const selectedTrack = dataObject.trackData.find(
      track => track.trackName === row.reviewingFor,
    );
    if (selectedTrack) {
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'Initiate',
          className: `lg:w-4/12`,
          data: {
            dataObject,
            isEdit: true,
            row,
            editData: selectedTrack,
          },
        }),
      );
    }
  };

  // delete track handler
  const deleteHandler = track => {
    if (track) {
      const userConfirmed = window.confirm('Are you sure you want to delete?');
      if (userConfirmed) {
        dispatch(
          reviewActions.deleteReviewsTrack({
            projectId: track.projectId,
            trackId: track.trackId,
          }),
        );
      } else {
        return;
      }
    }
  };

  let statusObj = {
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    YET_TO_START: 'Yet To Start',
  };
  //creating row Data
  const data = projectTrackDetails?.map((review, index) => {
    const formattedDate = review?.createdAt
      ? new Date(review.createdAt).toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
      : '-';
    return {
      id: index + 1,
      pgOwner: review?.pgOwner?.label,
      reviewingFor: review?.trackName,
      agileOwner: review?.agileOwner?.label || '',
      tagOwner: review?.tagOwner?.label || '',
      projectOwner: review?.projectOwner?.label || '',
      startDate: review?.startDate
        ? new Date(review?.startDate).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
        : formattedDate,
      // Score:
      //   review?.score === 0
      //     ? 'N/A'
      //     : review?.score
      //     ? review?.score.toFixed(1)
      //     : '-',
      endDate: review.endDate ? new Date(review.endDate).toISOString() : '-',
      rating: '-',
      reviewStatus: statusObj[review.reviewStatus] || '-',
      trackId: review?.id,
      projectId: review?.project?._id,
      _id: review?.id || index,
    };
  });
  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'reviewingFor',
      text: 'Review Type',
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: (cell, row, rowIndex) =>
        cell !== '-'
          ? new Date(cell).toLocaleString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : '-',
    },
    // {
    //   dataField: 'Score',
    //   text: 'Score',
    // },
    // {
    //   dataField: 'reviewStatus',
    //   text: 'Review Status',
    //   formatter: (cell, row) =>
    //     selectedOption?.option && projectDetails?.length > 0 ? (
    //       <div
    //         className={`rounded-md border w-fit py-1.5 px-4 text-center font-sans text-sm font-normal ${
    //           cell === 'Completed' ? 'border-[#00CE81]' : 'border-[#F28300]'
    //         } ${cell === 'Completed' ? 'bg-[#F0FDF4]' : 'bg-[#FEF6E6]'}`}
    //       >
    //         {cell !== '' ? cell : 'Yet to Start'}
    //       </div>
    //     ) : (
    //       '-'
    //     ),
    // },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      formatter: cell => (
        <div
          className={`${
            cell !== '-'
              ? 'border rounded-md  w-fit py-1.5 px-4 text-center font-sans text-sm font-normal'
              : ''
          } ${
            cell === statusObj.COMPLETED
              ? ' border-[#00CE81] text-[#00CE81] bg-[#F0FDF4]'
              : cell === statusObj.IN_PROGRESS
              ? 'border-[#FFD700] text-[#ac9000] bg-[#F8F8F8]'
              : cell === statusObj.YET_TO_START
              ? 'border-[#F28300] text-[#F28300] bg-[#F8F8F8]'
              : ''
          }`}
        >
          {cell !== '' && cell !== statusObj.YET_TO_START
            ? cell
            : statusObj.YET_TO_START}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) =>
        selectedOption?.option && projectTrackDetails?.length > 0 ? (
          row.reviewStatus === statusObj.COMPLETED ? (
            <img
              src={tableview}
              alt="view"
              className="cursor-pointer pl-[12px]	"
              onClick={() => handleRedirect(row, params)}
            />
          ) : (
            <Permission accessTo={USER_ROLES.ADMIN}>
              <Button
                handleClick={() => handleRedirect(row, params)}
                name={
                  row.reviewStatus === statusObj.IN_PROGRESS
                    ? 'Proceed'
                    : 'Start'
                }
                value={
                  row.reviewStatus === statusObj.IN_PROGRESS
                    ? 'proceed'
                    : 'start'
                }
                className={`save-btn text-lg rounded-[8px] text-white py-[7px] px-[12px] min-w-[70px] ${
                  selectedOption || row.category ? '' : 'hidden'
                } ${
                  row.reviewStatus === statusObj.IN_PROGRESS
                    ? 'bg-[#151d48]'
                    : 'bg-[#DE1186]'
                }`}
              />
            </Permission>
          )
        ) : (
          '-'
        ),
    },
    {
      dataField: 'actionOther',
      text: '',
      formatter: (cell, row) => {
        if (
          selectedOption?.option &&
          projectTrackDetails?.length > 0 &&
          row?.reviewStatus !== statusObj.COMPLETED
        ) {
          return (
            <Permission accessTo={USER_ROLES.ADMIN}>
              <img
                src={threeDots}
                alt="view"
                className="cursor-pointer h-[18px] mt-2.5"
                onClick={event => handleDotsClick(event, row.id)}
              />
              <div>
                <div
                  id={row.id}
                  className="options-container three-dots bg-white text-black p-4 hidden inline-grid absolute translate-x-7 -translate-y-5 whitespace-nowrap rounded bg-white px-2 py-1 text-black"
                >
                  <div
                    className="border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      handleEdit(row);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className="border-b border-[#f0f4f7] text-lg cursor-pointer hover:bg-[#d8d1da80] mt-1 mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      deleteHandler(row);
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            </Permission>
          );
        }
        return null;
      },
    },
  ];

  const handleRedirect = (row, params) => {
    navigate(`/review/track/${row.trackId}`);
  };

  const handleBackBtnClick = () => {
    try {
      navigate(`/review/${projectAccountDetails?.businessUnitId}`);
    } catch (e) {}
  };

  useEffect(() => {
    addDotsEventListener();
    return removeDotsEventListener();
  }, []);

  useEffect(() => {
    if (params?.accountId) {
      dispatch(reviewActions.getProjectAccountDetails(params.accountId));
    }
    return () => {
      dispatch(reviewActions.clearProjectAccountDetails(params.accountId));
    };
  }, [dispatch, params.accountId, reviewActions]);

  useEffect(() => {
    if (
      projectAccountDetails &&
      projectAccountDetails?.projects &&
      !Object.keys(selectedOption).length
    ) {
      let details = [...projectAccountDetails?.projects];
      let projectNames = {};

      details.sort((a, b) => a.projectName.localeCompare(b.projectName));
      details.forEach(
        project => (projectNames[project?._id] = project?.projectName),
      );
      setProjectData(projectNames);
      // if (Object.keys(projectNames).includes(selectedProject.id)) {
      //   setSelectedOption({
      //     id: selectedProject.id,
      //     option: selectedProject.option,
      //   });
      // }
      if (projectName) {
        const projectId = Object?.keys(projectNames).find(
          key => projectNames[key] === projectName,
        );
        setSelectedOption({
          id: projectId,
          option: projectNames[projectId],
        });
      } else if (state?.project) {
        setSelectedOption({
          id: state?.project._id,
          option: state?.project.projectName,
        });
      } else {
        setSelectedOption({
          id: Object.keys(projectNames)[0],
          option: Object.values(projectNames)[0],
        });
      }
    }
  }, [params.accountId, projectAccountDetails, selectedOption, state?.project]);

  useEffect(() => {
    if (Object.keys(selectedOption).length) {
      dispatch(reviewActions.getProjectReviewDetails(selectedOption));
    }
  }, [dispatch, reviewActions, selectedOption]);

  // handle project Data dropdown selection
  const handleSelects = option => {
    const projectId = Object.keys(projectData).find(
      key => projectData[key] === option,
    );
    setSelectedOption({ id: projectId, option });
  };
  const showProjectReviewDetails = () => {
    if (selectedOption?.option && projectTrackDetails?.length > 0) {
      return (
        <DashboardTable
          data={data}
          columns={columns}
          classcategory={'tablereviewpage'}
        />
      );
    } else {
    }
  };
  const dynamicClassName = selectedOption?.option
    ? 'border w-1/2 ml-4 px-6 py-3 font-medium border-[#de1186] rounded-md text-2xl text-[#de1186] hover:bg-[#de1186] hover:text-[#fff] transition'
    : 'border w-1/2 ml-4 px-6 py-3 font-medium border-[#ccc] rounded-md text-2xl text-[#ccc] cursor-not-allowed';
  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div className="review-listing">
          <div className="flex justify-between">
            <div className="inline-flex">
              <img
                src={leftArrow}
                alt="back"
                className="cursor-pointer h-8 mt-2 mr-2 -ml-1.5 filter grayscale"
                onClick={handleBackBtnClick}
              />
              <h2 className="breadcrumb-heading text-[#151D48] text-[28px] mb-4 font-semibold">
                {/* show Track name aling with Project Name */}
                {projectAccountDetails?.accountName}
              </h2>
            </div>
            {/* {projectReviewDetails[selectedOption.id]?.overallScore ? (
              <div className="flex">
                <img className="star" src={stars} alt="str" />
                <div className="rating-details">
                  <span className="rating">
                    {Number.isInteger(
                      projectReviewDetails[selectedOption.id]?.overallScore,
                    )
                      ? projectReviewDetails[
                          selectedOption.id
                        ]?.overallScore.toFixed(1)
                      : projectReviewDetails[selectedOption.id]?.overallScore}
                  </span>
                  <span className="rating-total">Overall Ratings</span>
                </div>
              </div>
            ) : null} */}
          </div>
          {/* {projectReviewDetails[selectedOption.id]?.overallScore ? (
            <Breadcrumb items={breadcrumbItems} classToAdd={'-mt-6'} />
          ) : (
            <Breadcrumb items={breadcrumbItems} />
          )} */}
          <Breadcrumb items={breadcrumbItems} />
          {/* <div className="wrapper mt-45">
            <img
              className="prj-img"
              src={tataplay}
              alt="view"
              style={{ filter: 'grayscale(75%)', opacity: 0.5 }}
            /> */}
          {/* <Button
          handleClick={() => {}}
          name="Change"
          value="change"
          className="change-btn"
        /> 
          </div> */}
          <div className="flex justify-start mt-20 text-2xl font-medium text-[#151D48]">
            Please Select Project
          </div>
          <div className="tabs">
            <Dropdown
              options={Object.values(projectData)}
              onSelect={handleSelects}
              selectedOption={selectedOption.option}
              selectedOptionStyle={{ padding: '10px' }}
              className="border-[#737791] drp-2 relative w-full border border-solid border-1 rounded-[4px]"
              placeholder="Select Project"
              optionsClassName="custom-options1"
            />
            <Permission accessTo={USER_ROLES.ADMIN}>
              <button
                onClick={() => {
                  dispatch(
                    modalManagerActions.openPopUp({
                      modalType: 'Initiate',
                      className: `lg:w-4/12`,
                      data: dataObject,
                    }),
                  );
                }}
                className={dynamicClassName}
                disabled={!selectedOption?.option}
              >
                Initiate New Review
              </button>
            </Permission>
          </div>
          {selectedOption.option && projectTrackDetails.length ? (
            showProjectReviewDetails()
          ) : (
            <div className="text-2xl font-medium text-[#151D48]">
              No Review Available
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TrackPage;
