import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalManager } from './modalSlice/selector';

import { useModalManagerSlice } from './modalSlice';
import CreateParameters from 'app/pages/Setup/components/CreateParameters';
import InitiateReview from 'app/pages/Review/components/TrackPage/InitiateReview';
import ReviewCompletionPopup from 'app/pages/Review/components/AssesmentListing/ReviewCompletionPopup';
import FollowUpDatesPopup from 'app/pages/Review/components/AssesmentListing/FollowUpDatesPopup';
import ErrorPopup from 'app/common/components/ErrorBoundary/ErrorPopup';
import CloseProjectPopup from 'app/pages/Review/components/AssesmentListing/CloseProjectPopup';
import AssessmentListPopup from 'app/pages/DashboardCalendar/components/CalendarTrackList/AssessmentListPopup';
import { image } from '../../../../assets/Common/commonImages';
import ConfirmPopup from 'app/pages/Permission/components/ConfirmPopup';
import ConfirmationSkillPermissionPopup from 'app/pages/SkillsPermission/components/ConfirmationPopup';
import ConfirmationSkillPopup from 'app/pages/Skill/components/ConfirmationSkillPopup';
import SkillPopup from 'app/pages/Skill/components/SkillPopup';
import ConfirmationPopup from '../components/ConfirmationPopup';
import AddSkillPopup from 'app/pages/Projects/components/AddSkill';
import DomainPopUp from 'app/pages/Domain/components/DomainPopup';
import ConfirmationDomainPopup from 'app/pages/Domain/components/ConfirmationDomainPopup';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import AddDomainPopup from 'app/pages/Projects/components/AddDomains';

const ModalManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalState = useSelector(modalManager);
  const { actions: modalManagerActions } = useModalManagerSlice();

  const showModal = () => {
    if (modalState?.modalType === 'CREATE_PARAMETERS') {
      return <CreateParameters />;
    } else if (modalState?.modalType === 'Initiate') {
      return <InitiateReview data={modalState.data} />;
    } else if (modalState?.modalType === 'ReviewCompletion') {
      return <ReviewCompletionPopup data={modalState?.data} />;
    } else if (modalState?.modalType === 'FollowUpDates') {
      return <FollowUpDatesPopup data={modalState?.data} />;
    } else if (modalState?.modalType === 'CloseProject') {
      return <CloseProjectPopup data={modalState?.trackId} />;
    } else if (modalState?.modalType === 'ShowAssessments') {
      return <AssessmentListPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'ConfirmPopup') {
      return <ConfirmPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'AddSkillPopup') {
      return <SkillPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'ErrorPopup') {
      return <ErrorPopup />;
    } else if (modalState?.modalType === 'ConfirmationSkillPopup') {
      return <ConfirmationSkillPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'ConfirmationSkillPermissionPopup') {
      return <ConfirmationSkillPermissionPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'ConfirmationPopup') {
      return <ConfirmationPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'AddSkillPopupInProjects') {
      return <AddSkillPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'AddDomainPopupInProjects') {
      return <AddDomainPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'ConfirmationDomainPopup') {
      return <ConfirmationDomainPopup data={modalState.data} />;
    } else if (modalState?.modalType === 'AddDomainPopup') {
      return <DomainPopUp data={modalState.data} />;
    }
  };
  // Prevent scrolling on the background page when modal is open
  useEffect(() => {
    document.body.style.overflow = modalState?.showModal ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalState?.showModal]);

  return (
    modalState?.showModal && (
      <div className="popup">
        <div
          className={`popup_inner ${
            modalState?.modalClassName ? modalState.modalClassName : ''
          }`}
        >
          <div className="header flex justify-between items-center border-b">
            <h2 className="font-bold text-3xl ml-16">{modalState?.heading}</h2>
            {modalState?.modalType !== 'ErrorPopup' ? (
              <button
                onClick={() => {
                  dispatch(modalManagerActions.closePopUp());
                  if (modalState?.modalType === 'AddSkillPopup') {
                    const newSearchParams = new URLSearchParams(
                      window.location.search,
                    );
                    newSearchParams.delete('openModal');

                    navigate(
                      `${
                        window.location.pathname
                      }?${newSearchParams.toString()}`,
                      { replace: true },
                    );
                  }
                }}
                className="font-bold m-4 underline flex self-end	"
              >
                <img src={image} alt="close" />
              </button>
            ) : null}
          </div>
          <div
            className="modal-body px-16"
            style={{
              height: modalState?.modalType === 'Initiate' ? '70vh' : 'auto',
              overflow:
                modalState?.modalType === 'ReviewCompletion'
                  ? 'hidden'
                  : modalState?.modalType === 'Initiate'
                  ? 'scroll'
                  : modalState?.modalType === 'FollowUpDates'
                  ? 'scroll'
                  : '',

              maxHeight: modalState?.modalType === 'FollowUpDates' && '60vh',
              minHeight: modalState?.modalType === 'FollowUpDates' && '40vh',
            }}
          >
            {showModal()}
          </div>
        </div>
      </div>
    )
  );
};

export default ModalManager;
