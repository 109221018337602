import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { SkillSaga } from './saga';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  SkillStateType,
  SkillData,
  CompetencyDataType,
  SearchedSkillStateType,
} from '../types';

export const initialState: SkillStateType = {
  view: 'Skill',
  error: null,
  loading: false,
  skillData: [],
  rolesDeleted: false,
  competencyData: [],
  skillUpdated: false,
  searchedSkills: [],
};

const slice = createSlice({
  name: 'Skill',
  initialState,
  reducers: {
    getSkill(state: SkillStateType) {
      state.loading = true;
    },
    gotSkill(state: SkillStateType, action: PayloadAction<SkillData[]>) {
      const Skill = action.payload;
      state.skillData = Skill;
      state.loading = false;
    },
    getSearchedSkills(state: SkillStateType) {
      state.loading = true;
    },
    gotSearchedSkills(
      state: SkillStateType,
      action: PayloadAction<SearchedSkillStateType[]>,
    ) {
      const Skill = action.payload;
      state.searchedSkills = Skill;
      state.loading = false;
    },
    clearSearchedSkills(state: SkillStateType) {
      state.searchedSkills = [];
      state.loading = false;
    },
    createSkill(state: SkillStateType) {
      state.loading = true;
    },
    updateSkill(state: SkillStateType) {
      state.loading = true;
    },
    approveSkill(state: SkillStateType) {
      state.loading = true;
    },
    skillCreated(state: SkillStateType, action: PayloadAction<SkillData>) {
      state.skillData = [...state.skillData, action.payload];
    },
    deleteSkill(state: SkillStateType) {
      state.loading = true;
    },
    resetState(state: SkillStateType) {
      state.skillData = initialState.skillData;
    },
    // rolesError(state: SkillStateType, action) {
    //   state.error = action.payload;
    //   state.loading = false;
    // },
    removeLoader(state: SkillStateType) {
      state.loading = false;
    },
    getCompetency(state: SkillStateType) {
      state.loading = true;
    },
    setCompetancy(
      state: SkillStateType,
      action: PayloadAction<CompetencyDataType[]>,
    ) {
      state.competencyData = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: SkillActions } = slice;

export const useSkillSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: SkillSaga });
  return { actions: slice.actions };
};
