import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { request } from 'app/common/utils/request';
import { SkillActions as actions } from '.';
import { commonActions } from 'app/common/slices/errorBoundarySlice';

export function* getSkill({ payload }) {
  const { search } = payload;
  yield delay(500);
  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/skillMapping`;
  if (search && search.trim().length > 0) {
    requestURL += `?name=${encodeURIComponent(search)}`;
  }
  try {
    const skillData = yield call(request, requestURL);
    yield put(actions.resetState());
    yield put(actions.gotSkill(skillData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* createSkill(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/skillMapping`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    //yield put(actions.rolesCreated(rolesSkill));
    // yield put(actions.resetState());
    yield put(actions.getSkill({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    toast.error(err.message);
    yield put(actions.removeLoader());
  }
}

export function* updateSkill(action) {
  yield delay(500);
  const { skillId, skillDetails } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/skillMapping/${skillId}`;
  try {
    yield call(request, requestURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(skillDetails),
    });
    yield put(actions.getSkill({}));
    yield put(actions.removeLoader());
    //yield put(actions.rolesCreated(rolesSkill));
    // yield put(actions.resetState());
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    toast.error(err.message);
    yield put(actions.removeLoader());
  }
}

export function* deleteSkill(action) {
  const { skillId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/skillMapping/${skillId}/remove`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
    });
    toast.success('Skill deleted successfully.');
    // yield put(actions.resetState());
    yield put(actions.getSkill({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* getCompetency() {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/competency`;

  try {
    const competencyData = yield call(request, requestURL);
    yield put(actions.setCompetancy(competencyData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* approveSkill(action) {
  yield delay(500);
  const { skillId, skillDetails } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/skillMapping/${skillId}/approve`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(skillDetails),
    });
    //yield put(actions.rolesCreated(rolesSkill));
    // yield put(actions.resetState());
    yield put(actions.getSkill({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    toast.error(err.message);
    yield put(actions.removeLoader());
  }
}

export function* getSearchedSkills({ payload }) {
  const { search } = payload;
  yield delay(500);

  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/skillMapping/search?queryString=${search}`;

  try {
    const skillsData = yield call(request, requestURL);
    yield put(actions.gotSearchedSkills(skillsData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}
export function* SkillSaga() {
  yield takeLatest(actions.getSkill, getSkill);
  yield takeLatest(actions.createSkill, createSkill);
  yield takeLatest(actions.updateSkill, updateSkill);
  yield takeLatest(actions.deleteSkill, deleteSkill);
  yield takeLatest(actions.getCompetency, getCompetency);
  yield takeLatest(actions.approveSkill, approveSkill);
  yield takeLatest(actions.getSearchedSkills, getSearchedSkills);
}
