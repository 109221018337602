import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { request } from 'app/common/utils/request';
import { DomainActions as actions } from '.';
import { commonActions } from 'app/common/slices/errorBoundarySlice';

export function* getDomain({ payload }) {
  const { search } = payload;
  yield delay(500);
  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/domain`;
  if (search && search.trim().length > 0) {
    requestURL += `?name=${encodeURIComponent(search)}`;
  }
  try {
    const domainData = yield call(request, requestURL);
    yield put(actions.resetState());
    yield put(actions.gotDomain(domainData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* createDomain(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/domain`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    yield put(actions.getDomain({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    toast.error(err.message);
    yield put(actions.removeLoader());
  }
}

export function* updateDomain(action) {
  yield delay(500);
  const { domainId, domainDetails } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/domain/${domainId}`;
  try {
    yield call(request, requestURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(domainDetails),
    });
    yield put(actions.getDomain({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    toast.error(err.message);
    yield put(actions.removeLoader());
  }
}

export function* deleteDomain(action) {
  const { domainId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/domain/${domainId}/remove`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
    });
    toast.success('Domain deleted successfully.');
    yield put(actions.getDomain({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* approveDomain(action) {
  yield delay(500);
  const { domainId, domainDetails } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/domain/${domainId}/approve`;
  try {
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(domainDetails),
    });
    yield put(actions.getDomain({}));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    toast.error(err.message);
    yield put(actions.removeLoader());
  }
}

export function* getSearchedDomains({ payload }) {
  const { search } = payload;
  yield delay(500);

  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/domain/search?queryString=${search}`;

  try {
    const domainsData = yield call(request, requestURL);
    yield put(actions.gotSearchedDomains(domainsData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* DomainSaga() {
  yield takeLatest(actions.getDomain, getDomain);
  yield takeLatest(actions.createDomain, createDomain);
  yield takeLatest(actions.updateDomain, updateDomain);
  yield takeLatest(actions.deleteDomain, deleteDomain);
  yield takeLatest(actions.approveDomain, approveDomain);
  yield takeLatest(actions.getSearchedDomains, getSearchedDomains);
}
