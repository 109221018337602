import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import DashboardTable from 'app/common/components/Table/table';
import { useSkillSlice } from './skillSlice';
import { SkillSelector } from './skillSlice/selector';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { threeDots } from '../../assets/Common/commonImages';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import { SKILL_STATUS } from 'app/common/constants/constants';
import Dropdown from 'app/common/components/DropdownNew';
import { SkillData } from './types';
import { selectUser } from 'app/common/slices/authslice/selectors';
import {
  handleDotsClick,
  addDotsEventListener,
  removeDotsEventListener,
} from 'app/common/utils/helperFn';
import { USER_ROLES } from 'app/common/services';
import { infoSmallImage } from 'app/assets/Common/commonImages';
import './style.css';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
// const LIMIT = 10;

type competency = {
  value: string;
  name: string;
};
const Skill: React.FC = () => {
  const { actions } = useSkillSlice();
  const permissions = useSelector(SkillSelector);
  const userResp = useSelector(selectUser);
  const { loading, competencyData } = permissions;
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const [query, setQuery] = useState({ competency: [], status: 'all' });
  const [competency, setCompetency] = useState<competency[]>([]);
  const [skillListingData, setSkillListingData] = useState<SkillData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isModalTriggered, setIsModalTriggered] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  let statusArr = [...SKILL_STATUS];
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.getSkill({})); //blank object send to avoid ts error, need to check
  }, [actions, dispatch]);

  useEffect(() => {
    dispatch(actions.getCompetency({})); //blank object send to avoid ts error, need to check
    addDotsEventListener();
    return removeDotsEventListener;
  }, [actions, dispatch]);

  useEffect(() => {
    setSkillListingData(permissions?.skillData);
  }, [permissions?.skillData]);

  useEffect(() => {
    if (competency.length === 0) {
      const userDataNew = competencyData.map(data => ({
        value: data?._id,
        name: data?.name,
      }));
      setCompetency(userDataNew);
      setIsDataReady(true);
    }
  }, [competency.length, competencyData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (
      queryParams.get('openModal') === 'true' &&
      isDataReady &&
      !isModalTriggered &&
      competency?.length
    ) {
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'AddSkillPopup',
          heading: 'Add Skills',
          modalClassName: 'lg:w-4/12 addSkillPopup',
          data: {
            competencyData: competency,
            setCurrentPage: setCurrentPage,
            skills: skillListingData,
            setQuery: setQuery,
          },
        }),
      );
      setIsModalTriggered(true);
    }
  }, [location.search, dispatch, isDataReady, competency, isModalTriggered]);

  const deleteSkillHandler = (row: { _id: any; name: any }) => {
    dispatch(
      modalManagerActions.openPopUp({
        modalType: 'ConfirmationSkillPopup',
        modalClassName: `w-[500px] rounded-xl`,
        data: { skillId: row?._id, name: row.name, setQuery: setQuery },
      }),
    );
  };
  const createSkillListing = row => {
    if (skillListingData?.length) {
      return skillListingData.map(val => {
        if (row?._id !== val?._id) return { value: val?._id, label: val?.name };
        return {};
      });
    }
    return [];
  };
  const searchSkills = (searchStr: string) => {
    dispatch(
      actions.getSkill({
        search: searchStr,
      }),
    );
  };

  const debouncedSearch = useCallback(debounce(searchSkills, 500), []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const rowsPerPage = 10;

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell: any, row: any, rowIndex: number) =>
        rowIndex + 1 + (currentPage - 1) * rowsPerPage,
    },
    {
      dataField: 'name',
      text: 'Skill Name',
    },
    {
      dataField: 'aliases',
      text: 'Aliases',
      formatter: (cell: any, row: { aliases: { toString: () => any } }) =>
        row.aliases?.toString(),
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell: any, row: { status: string }) => {
        const status = statusArr?.find(status => status.value === row?.status);
        const className =
          status?.value === 'pending'
            ? 'border rounded-md py-1.5 px-4 text-base text-center border-[#F28300] text-[#F28300] bg-[#F8F8F8]'
            : status?.value === 'approved'
            ? 'border rounded-md py-1.5 px-4 text-base text-center border-[#00CE81] text-[#00CE81] bg-[#F0FDF4]'
            : '';

        return <span className={className}>{status?.label || ''}</span>;
      },
    },
    {
      dataField: 'competency',
      text: 'Competency',
      formatter: (cell, row, rowIndex) => {
        const competencyNames = row?.metadata?.competencyData?.map(
          comp => comp.name,
        );

        if (!competencyNames || competencyNames.length === 0) {
          return '-';
        }

        const displayedNames = competencyNames.slice(0, 2).join(', ');
        const tooltipNames = competencyNames.slice(0).join(', ');
        return (
          <span className="inline-block">
            {displayedNames}
            {competencyNames?.length > 2 && tooltipNames && (
              <img
                src={infoSmallImage}
                alt="ellipsis icon"
                className="h-[17px] cursor-pointer inline-block ml-1"
                title={tooltipNames}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell: any, row) => (
        <>
          {row?.status === 'approved' ||
          (row?.status === 'pending' &&
            userResp?.user?.skillRole === USER_ROLES.CREATOR &&
            row.createdBy !== userResp?.user?.id) ? (
            <span className="pl-2"> - </span>
          ) : (
            <img
              src={threeDots}
              alt="view"
              className="cursor-pointer h-[18px] mt-2.5"
              onClick={event => handleDotsClick(event, row?._id)}
            />
          )}
          {}
          <div
            id={row?._id}
            className="options-container three-dots bg-white text-black p-4 absolute translate-x-7 -translate-y-5 whitespace-nowrap rounded px-2 py-1 shadow-xl z-20 hidden"
          >
            {row?.status !== 'approved' &&
              (userResp?.user?.skillRole === USER_ROLES.APPROVER ||
                userResp?.user?.skillRole === USER_ROLES.SUPER_ADMIN) && (
                <div
                  className="border border-solid border-0 text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                  onClick={() => {
                    dispatch(
                      modalManagerActions.openPopUp({
                        modalType: 'AddSkillPopup',
                        heading: `Approve Skill - ${row?.name}`,
                        modalClassName: `lg:w-4/12 addSkillPopup`,
                        data: {
                          existingData: row,
                          competencyData: competency,
                          approve: true,
                          skillListingData: createSkillListing(row),
                          setQuery: setQuery,
                        },
                      }),
                    );
                  }}
                >
                  Approve
                </div>
              )}
            {row?.status !== 'approved' &&
              userResp?.user?.skillRole !== USER_ROLES.VIEWER && (
                <>
                  <div
                    className="border-0 text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      dispatch(
                        modalManagerActions.openPopUp({
                          modalType: 'AddSkillPopup',
                          heading: `Edit Skill - ${row?.name}`,
                          modalClassName: `lg:w-4/12 addSkillPopup`,
                          data: {
                            existingData: row,
                            competencyData: competency,
                          },
                        }),
                      );
                    }}
                  >
                    Edit
                  </div>
                  {row?.status !== 'approved' &&
                    (userResp?.user?.skillRole === USER_ROLES.CREATOR ||
                      userResp?.user?.skillRole === USER_ROLES.APPROVER ||
                      userResp?.user?.skillRole === USER_ROLES.SUPER_ADMIN) && (
                      <div
                        className="text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                        onClick={() => deleteSkillHandler(row)}
                      >
                        Remove
                      </div>
                    )}
                </>
              )}
          </div>
        </>
      ),
    },
  ];

  const filterData = useCallback(
    (filterArr: any, statusFilter: any): SkillData[] => {
      const permittedValues =
        filterArr.length > 0 ? filterArr.map(val => val.value) : [];

      let filteredData = permissions?.skillData || [];

      if (statusFilter && statusFilter !== 'all') {
        filteredData = filteredData.filter(
          data => data?.status === statusFilter,
        );
      }

      if (permittedValues.length > 0) {
        filteredData = filteredData?.filter(skill =>
          skill?.metadata?.competency.some(item =>
            permittedValues.includes(item),
          ),
        );
      }

      return filteredData;
    },
    [permissions?.skillData],
  );

  const onAdd = useCallback(
    (field: string, value: string) => {
      const updatedValue =
        field === 'status' ? value : [...query[field], value];
      if (field === 'status') {
        let updatedData = filterData(query?.competency, updatedValue);
        setSkillListingData(updatedData);
      } else {
        const filteredArray = filterData(updatedValue, query?.status);
        setSkillListingData(filteredArray);
      }
      setQuery(prevDetails => ({
        ...prevDetails,
        [field]: updatedValue,
      }));
    },
    [filterData, permissions?.skillData, query],
  );

  const onRemove = (field: string, value: { value: string }) => {
    const updatedFilter = query[field].filter(
      (i: { value: any }) => i.value !== value.value,
    );
    const filteredArray = filterData(updatedFilter, query.status);
    setQuery(prevDetails => ({
      ...prevDetails,
      [field]: updatedFilter,
    }));
    setSkillListingData(filteredArray);
  };

  return (
    <>
      {!skillListingData.length && loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="heading pb-9">
            <h3 className="text-[#151D48] text-[28px] font-semibold">Skills</h3>
            <span className="text-[#151D48] text-[16px] font-normal opacity-50 ">
              Skills listing page
            </span>
          </div>
          <div className="flex justify-between pb-9">
            <div className="flex filter-group gap-4">
              <div className="dropdown">
                <Dropdown
                  options={statusArr}
                  onSelect={data => onAdd('status', data)}
                  selectedOption={query?.status}
                  className={`align-middle drp-2 w-60 mr-4 p-3 border border-solid border-1 border-[#737791] rounded-[4px] h-[40px]`}
                  optionsClassName="custom-options1 top-[87%]"
                  placeholder="Filter by Status"
                />
              </div>

              <Multiselect
                className={`p-0 border border-[#737791] rounded-[4px]`}
                // optionsClassName="custom-options1"
                placeholder="Filter by Competency"
                options={competency}
                onSelect={(list, data) => onAdd('competency', data)}
                onRemove={(list, data) => onRemove('competency', data)}
                displayValue={'name'}
                showCheckbox={true}
                hidePlaceholder={true}
              />
              <div className="wrap-search rounded-[6px] w-full relative">
                <input
                  type="text"
                  placeholder="Search Skills"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="border border-[#737791] w-full h-[40px] px-[12px] text-[#6B7280] border-1 rounded-[6px] focus:outline-none"
                />
              </div>
            </div>
            <button
              onClick={() => {
                dispatch(
                  modalManagerActions.openPopUp({
                    modalType: 'AddSkillPopup',
                    heading: 'Add Skills',
                    modalClassName: `lg:w-4/12 addSkillPopup`,
                    data: {
                      competencyData: competency,
                      setCurrentPage: setCurrentPage,
                      skills: skillListingData,
                      setQuery: setQuery,
                    },
                  }),
                );
              }}
              className="py-4 px-12 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186] h-full"
            >
              <i className="bi bi-plus"></i> Add Skills
            </button>
          </div>
          <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 row col-sm-12">
            {skillListingData && skillListingData.length ? (
              <>
                <DashboardTable
                  data={skillListingData}
                  columns={columns}
                  defaultArray={[{ dataField: 'role', order: 'asc' }]}
                  needPagination={true}
                  className={'skillListing'}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </>
            ) : (
              <div className="text-align-center">No Data Found</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Skill;
