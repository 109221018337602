import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardTable from 'app/common/components/Table/table';
import { useDomainSlice } from './domainSlice';
import { DomainSelector } from './domainSlice/selector';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { threeDots } from '../../assets/Common/commonImages';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import { DOMAIN_STATUS } from 'app/common/constants/constants';
import Dropdown from 'app/common/components/DropdownNew';
import { selectUser } from 'app/common/slices/authslice/selectors';
import { handleDotsClick } from 'app/common/utils/helperFn';
import { USER_ROLES } from 'app/common/services';
import { debounce } from 'lodash';
import './style.css';
import { useLocation } from 'react-router-dom';

const Domain = () => {
  const { actions } = useDomainSlice();
  const { loading, domainData } = useSelector(DomainSelector);
  const userResp = useSelector(selectUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const [statusQuery, setStatusQuery] = useState('all');
  const [domainListingData, setDomainListingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalTriggered, setIsModalTriggered] = useState(false);

  let statusArr = [...DOMAIN_STATUS];

  useEffect(() => {
    dispatch(actions.getDomain({}));
  }, [actions, dispatch]);

  useEffect(() => {
    setDomainListingData(domainData);
  }, [domainData]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('openModal') === 'true' && !isModalTriggered) {
      dispatch(
        modalManagerActions.openPopUp({
          modalType: 'AddDomainPopup',
          heading: 'Add Domain',
          modalClassName: `lg:w-4/12 addDomainPopup`,
          data: {
            domain: domainListingData,
            setCurrentPage: setCurrentPage,
            setQuery: setStatusQuery,
          },
        }),
      );
      setIsModalTriggered(true);
    }
  }, [location.search, dispatch, isModalTriggered]);

  const deleteDomainHandler = row => {
    dispatch(
      modalManagerActions.openPopUp({
        modalType: 'ConfirmationDomainPopup',
        modalClassName: `w-[500px] rounded-xl`,
        data: { domainId: row?._id, name: row?.name, setQuery: setStatusQuery },
      }),
    );
  };
  const createDomainListing = row => {
    if (!domainListingData?.length) return [];

    return domainListingData
      .filter(val => row?._id !== val?._id)
      .map(val => ({ value: val?._id, label: val?.name }));
  };

  const rowsPerPage = 10;

  const searchDomains = searchStr => {
    dispatch(
      actions.getDomain({
        search: searchStr,
      }),
    );
  };

  const debouncedSearch = useCallback(debounce(searchDomains, 500));

  const handleSearchChange = event => {
    setSearchQuery(event?.target?.value);
    debouncedSearch(event?.target?.value);
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1 + (currentPage - 1) * rowsPerPage;
      },
    },
    {
      dataField: 'name',
      text: 'Domain Name',
    },
    {
      dataField: 'aliases',
      text: 'Aliases',
      formatter: row => (row ?? []).join(', '),
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: row => {
        const status = statusArr?.find(status => status.value === row);
        const className =
          status?.value === 'pending'
            ? 'border rounded-md py-1.5 px-4 text-base text-center border-[#F28300] text-[#F28300] bg-[#F8F8F8]'
            : status?.value === 'approved'
            ? 'border rounded-md py-1.5 px-4 text-base text-center border-[#00CE81] text-[#00CE81] bg-[#F0FDF4]'
            : '';

        return <span className={className}>{status?.label || ''}</span>;
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => (
        <>
          {row?.status === 'approved' ||
          (row?.status === 'pending' &&
            userResp?.user?.skillRole === USER_ROLES.CREATOR &&
            row.createdBy !== userResp?.user?.id) ? (
            <span className="pl-2"> - </span>
          ) : (
            <img
              src={threeDots}
              alt="view"
              className="cursor-pointer h-[18px] mt-2.5"
              onClick={event => handleDotsClick(event, row?._id)}
            />
          )}
          <div
            id={row?._id}
            className="options-container three-dots bg-white text-black p-4 absolute translate-x-7 -translate-y-5 whitespace-nowrap rounded px-2 py-1 shadow-xl z-20 hidden"
          >
            {row?.status !== 'approved' &&
              (userResp?.user?.skillRole === USER_ROLES.APPROVER ||
                userResp?.user?.skillRole === USER_ROLES.SUPER_ADMIN) && (
                <div
                  className="border-0  rounded-[4px] text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2"
                  onClick={() => {
                    dispatch(
                      modalManagerActions.openPopUp({
                        modalType: 'AddDomainPopup',
                        heading: `Approve Domain - ${row?.name}`,
                        modalClassName: `lg:w-4/12 addDomainPopup`,
                        data: {
                          existingData: row,
                          approve: true,
                          domainListingData: createDomainListing(row),
                          setQuery: setStatusQuery,
                          domain: domainListingData,
                        },
                      }),
                    );
                  }}
                >
                  Approve
                </div>
              )}
            {row?.status !== 'approved' &&
              userResp?.user?.skillRole !== USER_ROLES.VIEWER && (
                <>
                  <div
                    className="border-0  text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                    onClick={() => {
                      dispatch(
                        modalManagerActions.openPopUp({
                          modalType: 'AddDomainPopup',
                          heading: `Edit Domain - ${row?.name}`,
                          modalClassName: `lg:w-4/12 addDomainPopup`,
                          data: {
                            existingData: row,
                            setQuery: setStatusQuery,
                          },
                        }),
                      );
                    }}
                  >
                    Edit
                  </div>
                  {row?.status !== 'approved' &&
                    (userResp?.user?.skillRole === USER_ROLES.CREATOR ||
                      userResp?.user?.skillRole === USER_ROLES.APPROVER ||
                      userResp?.user?.skillRole === USER_ROLES.SUPER_ADMIN) && (
                      <div
                        className="text-lg cursor-pointer hover:bg-[#d8d1da80] mb-1 p-2 rounded-[4px]"
                        onClick={() => deleteDomainHandler(row)}
                      >
                        Remove
                      </div>
                    )}
                </>
              )}
          </div>
        </>
      ),
    },
  ];

  const onAdd = useCallback(
    (field, value) => {
      const updatedData =
        value !== 'all'
          ? domainData?.filter(domain => domain.status === value)
          : domainData;
      setStatusQuery(value);
      setDomainListingData(updatedData);
    },
    [domainData, statusQuery],
  );

  return (
    <>
      {!domainListingData?.length && loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="heading pb-9">
            <h3 className="text-[#151D48] text-[28px] font-semibold">Domain</h3>
            <span className="text-[#151D48] text-[16px] font-normal opacity-50 ">
              Domains listing page
            </span>
          </div>
          <div className="flex justify-between pb-9">
            <div className="flex filter-group">
              <div className="dropdown">
                <Dropdown
                  options={statusArr}
                  onSelect={data => onAdd('status', data)}
                  selectedOption={statusQuery}
                  className={`align-middle drp-2 w-60 mr-4 p-3 border border-solid border-1 border-[#737791] rounded-[4px]`}
                  optionsClassName="custom-options1 top-[87%]"
                  placeholder="Filter by Status"
                />
              </div>
              <div className="wrap-search rounded-[6px] w-full relative bg-[#F9FAFB]  border-[#737791]">
                <input
                  type="text"
                  placeholder="Search Domains"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="border border-[#737791] w-full h-[40px] px-[12px] text-[#6B7280] border-1 rounded-[6px] focus:outline-none"
                />
              </div>
            </div>
            <button
              onClick={() => {
                dispatch(
                  modalManagerActions.openPopUp({
                    modalType: 'AddDomainPopup',
                    heading: 'Add Domain',
                    modalClassName: `lg:w-4/12 addDomainPopup`,
                    data: {
                      domain: domainListingData,
                      setCurrentPage: setCurrentPage,
                      setQuery: setStatusQuery,
                    },
                  }),
                );
              }}
              className="py-4 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186] h-full"
            >
              <i className="bi bi-plus"></i> Add Domain
            </button>
          </div>
          <div className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10 row col-sm-12">
            {domainListingData && domainListingData.length ? (
              <>
                <DashboardTable
                  data={domainListingData}
                  columns={columns}
                  defaultArray={[{ dataField: 'role', order: 'asc' }]}
                  needPagination={true}
                  className={'domainListing'}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </>
            ) : (
              <div className="text-align-center">No Data Found</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Domain;
