import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useProjectSlice } from '../projectSlice';
import { projectList } from '../projectSlice/selector';
import { selectUser } from 'app/common/slices/authslice/selectors';
import { USER_ROLES } from 'app/common/services';
import { refreshIcon } from 'app/assets/Common/commonImages';
import { useSkillSlice } from 'app/pages/Skill/skillSlice';
import { SkillSelector } from 'app/pages/Skill/skillSlice/selector';
const AddSkillPopup = props => {
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: skillActions } = useSkillSlice();
  const userDetails = useSelector(selectUser);
  const { actions } = useProjectSlice();
  const permissions = useSelector(SkillSelector);
  const { skillData: skillListing } = permissions;
  const projects = useSelector(projectList);
  const {
    searchQuery,
    selectedStatusFilter,
    selectedReviewFilter,
    projectsData,
  } = projects;
  const { projectId, heading } = props?.data || {};

  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    const project = projectsData?.find(p => p._id === projectId);
    if (project?.skills) {
      setSelectedSkills(project?.skills);
    }
  }, [projectId, projectsData]);

  const handleRefreshSkills = async () => {
    try {
      dispatch(skillActions.getSkill({}));
    } catch (error) {
      console.error('Error fetching skills:', error);
    }
  };

  const approvedSkills =
    skillListing?.filter(skill => skill.status === 'approved') || [];

  const onAdd = (field, data) => {
    setSelectedSkills(data);
  };

  const onRemove = (field, data) => {
    setSelectedSkills(data);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!selectedSkills?.length) {
      alert('Please select at least one skill.');
      return;
    }
    const finalSkills = selectedSkills.map(skill => skill._id);
    dispatch(actions.setPage(1));
    dispatch(
      actions.updateProject({
        projectId,
        skills: finalSkills,
        search: searchQuery,
        statusFilter: selectedStatusFilter,
        reviewStatus: selectedReviewFilter,
      }),
    );
    dispatch(modalManagerActions.closePopUp());
  };

  const hasPermission =
    userDetails?.user?.skillRole &&
    [USER_ROLES.APPROVER, USER_ROLES.CREATOR, USER_ROLES.SUPER_ADMIN].includes(
      userDetails.user.skillRole,
    );

  return (
    <div className="py-[20px]">
      <p className="text-3xl font-medium text-[#151D48] text-center">
        {heading}
      </p>

      <div className="items-center mb-2">
        <label className="font-normal mb-3">Skills: </label>
        <div className="flex items-center space-x-2">
          <div className="w-[400px] min-h-[44px] border border-[#737791]">
            <Multiselect
              selectedValues={selectedSkills}
              options={approvedSkills}
              onSelect={data => onAdd('skills', data)}
              onRemove={data => onRemove('skills', data)}
              displayValue="name"
              showCheckbox
              placeholderText="Enter Skills"
              hidePlaceholder={true}
              avoidHighlightFirstOption={true}
            />
          </div>
          <button onClick={handleRefreshSkills} aria-label="Refresh skills">
            <img src={refreshIcon} alt="refresh icon" className="w-6 h-6" />
          </button>
        </div>
      </div>

      <div className="pt-6 mr-4">
        <span className="font-bold">Note:</span>

        {hasPermission ? (
          <span className="italic">
            {' '}
            In case the skill you want to add is not mentioned in the list ,
            click on the Create skill button below.
          </span>
        ) : (
          <span className="italic px-2">
            In case the skill you want to add is not mentioned in the list ,
            please contact competency owner.
          </span>
        )}
      </div>

      <div className="flex justify-end mt-12">
        {hasPermission && (
          <button
            className="text-[#de1186] py-2 px-6 font-medium text-xl rounded-xl ml-4 border border-solid border-[#de1186]"
            onClick={() => {
              window.open('/skills?openModal=true', '_blank');
            }}
          >
            Create Skill
          </button>
        )}
        <button
          className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl ml-4"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="text-[#de1186] py-4 px-12 font-medium text-xl rounded-xl ml-4 border border-solid border-[#de1186]"
          onClick={() => dispatch(modalManagerActions.closePopUp())}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddSkillPopup;
