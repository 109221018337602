import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_CONSTANTS } from 'app/common/constants/constants';
import logoTtn from '../../../assets/ttnlogo.png';
import google from '../../../assets/google.png';
import { selectUser } from 'app/common/slices/authslice/selectors';
import { USER_ROLES } from 'app/common/services';

function Login() {
  const userDetails = useSelector(selectUser);
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.LOGIN_AUTH}`;

  useEffect(() => {
    if (userDetails.user.name !== '') {
      if (
        userDetails?.user?.role &&
        userDetails?.user.role === USER_ROLES?.NO_ACCESS
      ) {
        navigate('/skills');
      } else if (
        userDetails?.user?.skillRole &&
        userDetails?.user.skillRole === USER_ROLES?.NO_ACCESS
      ) {
        navigate('/dashboard-calendar');
      } else if (
        userDetails?.user?.role &&
        userDetails?.user?.skillRole &&
        userDetails?.user?.role !== USER_ROLES?.NO_ACCESS &&
        userDetails?.user?.skillRole !== USER_ROLES?.NO_ACCESS
      ) {
        navigate('/dashboard-calendar');
      }
    }
  }, [navigate, userDetails.user.name]);

  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="flex justify-center items-center flex-col w-[35%] rounded-[16px] shadow-[0_16px_48px_rgba(0,0,0,.175)]">
        <div className="text-center px-16 py-16">
          <div>
            <img src={logoTtn} alt="TTN Logo" className="w-[25%] m-auto" />
            {/* <HealthProjectLogo /> */}
            <h1 className="text-[2.2rem] mb-[2rem] font-semibold">
              Welcome to Project health Dashboard!
            </h1>
          </div>

          <button
            className="py-4 px-16 rounded-xl text-xl font-semibold font-medium border border-[#de1186] text-[#fff] bg-[#de1186] m-auto hover:text-[#fff] hover:border-[#de1186] mt-[12px] flex justify-center items-center  "
            onClick={e => window.open(url, '_self')}
          >
            <img
              src={google}
              alt="TTN Logo"
              className="w-[17px] h-[17px] mr-[8px] mt-[-2px]"
            />
            LOGIN WITH GOOGLE
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
