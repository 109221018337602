import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactTags } from 'react-tag-autocomplete';
import Multiselect from 'multiselect-react-dropdown';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useSkillSlice } from '../skillSlice/index';
// import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import '../components/style.css';

const SkillPopup = ({ data }) => {
  const { existingData, competencyData, approve, skillListingData } = data;

  const [validationError, setValidationError] = useState({
    name: '',
    replaceAndMergeWith: '',
  });
  const [editSkill, setEditSkill] = useState(false);
  const [skillData, setSkillData] = useState({
    name: '',
    metadata: { competency: [], version: [] },
    aliases: [],
  });
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: skillActions } = useSkillSlice();

  useEffect(() => {
    if (existingData && Object.keys(existingData).length) {
      const data = { ...existingData };
      data.aliases = getValueLabelArr(existingData?.aliases);
      data.metadata = {
        ...existingData.metadata,
        competency: existingData?.metadata?.competency?.length
          ? existingData?.metadata?.competencyData.map(val => {
              return { value: val._id, name: val.name };
            })
          : [],
        version: getValueLabelArr(existingData?.metadata?.version),
        _id: existingData?._id,
      };
      data.replaceAndMergeWith = [];
      setSkillData(data);
      setEditSkill(true);
    }
  }, [existingData]);

  const getValueLabelArr = arr => {
    if (arr?.length) {
      return arr.map(val => {
        return { value: val, label: val };
      });
    }
    return arr;
  };

  const handleInputChange = useCallback(value => {
    setSkillData(prevDetails => ({
      ...prevDetails,
      name: value,
    }));
    setValidationError(prevDetails => ({
      ...prevDetails,
      name: '',
    }));
  }, []);

  const onAdd = useCallback((field, value) => {
    setSkillData(prevDetails => {
      const metadata = { ...prevDetails.metadata };
      if (field === 'version') {
        metadata[field].push(value);
      } else {
        metadata[field] = value;
      }
      return {
        ...prevDetails,
        metadata,
      };
    });
  }, []);

  const onRemove = useCallback((field, value) => {
    setSkillData(prevDetails => {
      const metadata = { ...prevDetails.metadata };
      if (field === 'version') {
        metadata[field] = metadata[field].filter((_, i) => i !== value);
      } else {
        metadata[field] = value;
      }
      return {
        ...prevDetails,
        metadata,
      };
    });
  }, []);

  const onAddTag = useCallback((field, value) => {
    setSkillData(prevDetails => {
      const data = { ...prevDetails };
      data[field] = [...data[field], value];
      return {
        ...data,
      };
    });
  }, []);

  const onRemoveTag = useCallback((field, value) => {
    setSkillData(prevDetails => {
      const data = { ...prevDetails };
      data[field] = data[field].filter((_, i) => i !== value);
      return {
        ...data,
      };
    });
  }, []);

  const handleSubmit = useCallback(() => {
    const flattenedDetails = {
      name: skillData.name,
      metadata: {
        competency: skillData.metadata.competency.map(v => v.value),
        version: skillData.metadata.version.map(v => v.label),
      },
      aliases: skillData.aliases.map(v => v.label),
    };

    if (!flattenedDetails.name.trim()) {
      setValidationError(prevDetails => ({
        ...prevDetails,
        name: 'This field is required',
      }));
      return;
    }
    if (editSkill && !approve) {
      flattenedDetails._id = skillData._id;
      dispatch(
        skillActions.updateSkill({
          skillId: skillData._id,
          skillDetails: flattenedDetails,
        }),
      );
    } else if (approve) {
      if (skillData?.replaceAndMergeWith.length > 1) {
        setValidationError(prevDetails => ({
          ...prevDetails,
          replaceAndMergeWith: 'Only 1 skill is allowed',
        }));
        return;
      }
      if (skillData?.replaceAndMergeWith.length) {
        flattenedDetails.replaceAndMergeWith =
          skillData?.replaceAndMergeWith[0]?.value;
      }
      const userConfirmed = window.confirm('Are you sure you want to approve?');
      if (userConfirmed) {
        dispatch(
          skillActions.approveSkill({
            skillId: skillData._id,
            skillDetails: flattenedDetails,
          }),
        );
      } else {
        return;
      }
    } else {
      dispatch(skillActions.createSkill(flattenedDetails));
    }
    dispatch(modalManagerActions.closePopUp());
    setValidationError(prevDetails => ({
      ...prevDetails,
      name: '',
    }));
  }, [
    skillData.name,
    skillData.metadata.competency,
    skillData.metadata.version,
    skillData.aliases,
    skillData._id,
    skillData?.replaceAndMergeWith,
    editSkill,
    approve,
    dispatch,
    modalManagerActions,
    skillActions,
  ]);

  return (
    <div className="flex flex-col">
      <label className="mt-2 ml-2.5 mb-3 font-normal">Name*</label>
      <input
        type="text"
        className="drp-1 ml-2.5 mb-3 pl-2.5 relative px-4 py-3.5 rounded-xl border-solid border border-[#b1b2b5] focus:outline-none focus:border-[#323334]"
        onChange={e => handleInputChange(e.target.value)}
        placeholder="Enter skill name"
        value={skillData.name}
      />
      {validationError?.name && (
        <div className="text-red-500 ml-2.5">{validationError?.name}</div>
      )}

      <label className="mt-2 ml-2.5 mb-3 font-normal">Aliases</label>
      <div className="flex flex-col ml-2.5">
        <div className=" mb-2">
          <ReactTags
            selected={skillData.aliases}
            suggestions={[]}
            onAdd={data => onAddTag('aliases', data)}
            onDelete={data => onRemoveTag('aliases', data)}
            allowNew
            placeholderText={'Enter aliases'}
          />
        </div>
      </div>

      <div className="flex flex-col mt-3 ml-2.5">
        <label className="mt-2 mb-3 font-normal">Meta Data</label>

        <div className="items-center mb-2">
          <label className="font-normal mb-3">Competency: </label>
          <Multiselect
            selectedValues={skillData?.metadata?.competency || []}
            options={competencyData}
            //onInput={e => onInput(e)}
            onSelect={data => onAdd('competency', data)}
            onRemove={data => onRemove('competency', data)}
            displayValue={'name'}
            showCheckbox
            placeholderText={'Enter Competency Stack'}
          />
          <label className="font-normal mb-3 mt-3">Version</label>
          <ReactTags
            selected={skillData?.metadata?.version || []}
            onAdd={data => onAdd('version', data)}
            onDelete={data => onRemove('version', data)}
            allowNew
            placeholderText={'Enter Version List'}
          />
        </div>
      </div>

      {existingData && approve && (
        <div className="flex flex-col ml-2.5 mt-3">
          <label className="mt-2 mb-3 font-normal">
            Replace and Merge with
          </label>
          <div className=" mb-2">
            <ReactTags
              selected={skillData?.replaceAndMergeWith || []}
              suggestions={skillListingData}
              onAdd={data => onAddTag('replaceAndMergeWith', data)}
              onDelete={data => onRemoveTag('replaceAndMergeWith', data)}
              allowNew
              placeholderText={'Search for a skill...'}
            />
            {validationError?.replaceAndMergeWith && (
              <div className="text-red-500 ml-2.5">
                {validationError?.replaceAndMergeWith}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex justify-end mt-12">
        <button
          className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl ml-4"
          onClick={handleSubmit}
        >
          {approve ? 'Approve' : 'Submit'}
        </button>
        <button
          className="text-[#de1186] py-4 px-12 font-medium text-xl rounded-xl ml-4 border border-solid border-[#de1186]"
          onClick={() => dispatch(modalManagerActions.closePopUp())}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SkillPopup;
