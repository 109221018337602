import React from 'react';
import { useDispatch } from 'react-redux';
import { useSkillSlice } from '../skillSlice';
import Button from 'app/common/components/Button';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';

interface skillProps {
  data: {
    skillId: string;
    name: string;
    setQuery: (query: { competency: any[]; status: string }) => void;
  };
}

const ConfirmationSkillPopup: React.FC<skillProps> = (props: skillProps) => {
  const { actions: skillActions } = useSkillSlice();
  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();

  const handleAddClick = () => {
    dispatch(
      skillActions.deleteSkill({
        skillId: props?.data?.skillId,
      }),
    );
    props.data.setQuery({ competency: [], status: 'all' });
    dispatch(modalManagerActions.closePopUp());
  };

  const handleCancel = () => {
    dispatch(modalManagerActions.closePopUp());
  };

  return (
    <>
      <div className="p-[20px]">
        <p className="text-3xl font-medium text-[#151D48] text-center">
          Are you really want to remove Skill <br />"{props.data.name}"{''} ?
        </p>
        <div className="pt-[26px] pl-[90px]">
          <Button
            handleClick={handleAddClick}
            className="py-4 px-12 ml-[15px] rounded-[10px] text-[#DE1186] px-[20px] text-2xl font-medium bg-white border border-solid border-1 border-[#DE1186]"
            name="YES"
          />
          <Button
            handleClick={handleCancel}
            className="py-4 px-12 ml-[15px] rounded-[10px] text-white px-[20px] text-2xl font-medium bg-[#DE1186]"
            name="NO"
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmationSkillPopup;
