// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundStyling {
  background-color: #f7f8f9;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
.heading {
  h3 {
    margin: 0 0 10px 0;
  }
  span {
    line-height: 30px;
  }
}
.react-tags__combobox{
  padding: 0.20rem 0.25rem;
}
.react-tags{
  display: flex;
}

.react-tags__list::-webkit-scrollbar {
  height: 8px;
}

.react-tags__list::-webkit-scrollbar-thumb {
  background-color: #737791;
  border-radius: 10px;
}

.react-tags__list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.react-tags__list{
  max-width: 225px;
  overflow-x: auto;
  display: inline-flex;
  flex-wrap: nowrap; 
  padding: 0;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/Projects/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;AACpB;AACA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,iBAAiB;EACnB;AACF;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;EACjB,UAAU;EACV,SAAS;AACX","sourcesContent":[".backgroundStyling {\n  background-color: #f7f8f9;\n  border-radius: 10px;\n  width: fit-content;\n}\n.heading {\n  h3 {\n    margin: 0 0 10px 0;\n  }\n  span {\n    line-height: 30px;\n  }\n}\n.react-tags__combobox{\n  padding: 0.20rem 0.25rem;\n}\n.react-tags{\n  display: flex;\n}\n\n.react-tags__list::-webkit-scrollbar {\n  height: 8px;\n}\n\n.react-tags__list::-webkit-scrollbar-thumb {\n  background-color: #737791;\n  border-radius: 10px;\n}\n\n.react-tags__list::-webkit-scrollbar-track {\n  background-color: #f1f1f1;\n}\n\n.react-tags__list{\n  max-width: 225px;\n  overflow-x: auto;\n  display: inline-flex;\n  flex-wrap: nowrap; \n  padding: 0;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
